@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');



:root {
	--mainColor: #949494;
	--mainColorLight: #5767aa;
	--secondaryColor: #eff31a;
	--textColor: #eee;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
	font-weight:bold;
	
}


nav a:hover {
color: var(--secondaryColor);

}
a {
color: #fff;
text-decoration: none;
font-size: 15px;
position: relative;


}

.dropdown {
position: relative;

}

.dropdown-content {
display: none;
position: absolute;
top: 100%;
left: 0;
background-color: #1c1c1f;
padding-top: 5px;
width: 120px;
z-index: 1;
}

.dropdown:hover .dropdown-content {
display: block;
}

.dropdown-content a {
color: #fff;
display: block;
text-decoration: none;
padding: 5px;
font-size: 12px;
}

.dropdown-content a:hover {
background-color: #000000;
}


/*ICONS*/



header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
    position: relative;
	display: flex;
	align-items: center;
}

.nav-item {
	color: #fcf82d;
}
.nav-item-2 {
	color: #fcf82d;
	}

.nav-item:hover {
color: white;
}
.nav-item-2:hover {
	color: white;
	}

.logoslider {
	position: absolute;
	z-index: 1;
	width: 200px;
	top: 13rem;
	left: 50%;
	transform: translateX(-50%);
  }

@media only screen and (max-width: 800px) {


header .nav-btn {
	visibility: visible;
	opacity: 1;
}
.nav-item {
	color: #25D366;
  }

  
  .nav-item:hover {
	color: white;
  }

  nav {
	position: fixed;
	top: -100vh;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
	background-color: var(--mainColor);
	transition: 0.5s;
	z-index: 9999; /* Increase z-index to display above other elements */
  }
nav .nav-items{
	flex-direction: column;
	margin: 1.5rem;
	
	
}

header .responsive_nav {
	transform: translateY(100vh);
}

nav .nav-close-btn {
	position: absolute;
	top: 2rem;
	right: 2rem;
}

nav .nav-items a {
	font-size: 1rem;
	margin: 0.7rem;

}
nav a:hover {
	color: var(--secondaryColor);

}

.dropdown-content  {
display: none;
position: absolute;
top: 100%;
left: -100%;
background-color: #393939;
padding-top: 3px;
z-index: 99999999;
width: 300px;
z-index: 1;
}

.dropdown-content a {
color: #fff;
display: block;
text-decoration: none;
padding: 1px;
}
.logoslider {
	position: absolute;
	z-index: 1;
	width: 200px;
	left: 50%;
	top: 10rem;
	transform: translateX(-50%);
  }

}




/*SLIDER MAIN*/
  .slider {
	width: 100%;
	height: 90vh;
	position: relative;
	overflow: hidden;
  }
  
  .slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transform: translateX(50%);
	transition: all 0.5s ease;
  }
  
  
  .slide img {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
  }
  
  .current {
	opacity: 1;
	transform: translateX(0);
  }
  
  .content {
	position: absolute;
	top: 2rem;
	left: 5rem;
	opacity: 0;
	width: 50%;
	color: #fff;
	padding: 1rem;
	background: rgba(0, 0, 0, 0.3);
	animation: slide-up 1s ease 0.2s;
	animation-delay: 1s;
	animation-fill-mode: forwards;
	visibility: hidden;
  }

/*TEXTO Y MAPA*/

@font-face {
  font-family: 'MiedingerW01-Bold';
  src: url('//db.onlinewebfonts.com/t/db8811fcaea2386d4f6e3bcf7e6d206b.woff2') format('woff2');

  font-weight: bold;
  font-style: normal;
}

.bloque-5 {
  text-align: center;

}

.bloque-5 h1 {
  font-size: 2rem;
font-weight: bold;
    color: yellow;
  padding-top: 30px;
}

.bloque-5 p {
  font-size: 1.5rem;
  color: #fff;
  font-family: 'Nunito', sans-serif;

}
.map {
position: absolute;
height: 500px;
width: 100%;

}

.tooltip {
	position: absolute;
	top: 20%;
	right: 20%;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.3);
	animation: fadeIn 0.3s ease-in-out;
	font-family: 'Nunito', sans-serif;
	font-weight: bold;
	
	}
	
	.tooltip ul {
	list-style-type: disc;
	margin-left: 1.5em;
	margin-top: 0.5rem;
	
	
	}
	
	.tooltip ul li {
	margin-bottom: 0.5em;
	margin-right: 1.5rem;
	
	
	
	
	}
	
	.tooltip h1 {
	color: rgb(0, 0, 0);
	text-align: center;
	font-weight: bold;
	padding: 10px;
	border-radius: 5px;
	background-color: #fcf82d;
	
	font-weight: bold;
	
	}
	
	.tooltip p {
	text-align: center;
	padding: 0.5rem;
	
	
	
	}
	
	@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateX(20px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
	}
	
	@keyframes slide-up {
	0% {
		visibility: visible;
		top: 0%;
	}
	100% {
		visibility: visible;
		top: 65%;
	}
	}
	
	@keyframes fadeInFromBottom {
		0% {
		  opacity: 0;
		  transform: translateY(100%);
		}
		100% {
		  opacity: 1;
		  transform: translateY(calc(0 -50%)); /* adjust 10px to fit your tooltip */
		}
	  }
	
	@media only screen and (max-width: 800px) {
		.tooltip {
			position: absolute;
			top: 420px;
			left: 50%;
			width: 60%;
			transform: translate(-50%, -50%);
			background-color: #fff;
			border: 1px solid #ccc;
			border-radius: 5px;
			box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
			animation: fadeInFromBottom 0.3s ease-in-out;
		  }
	
			.map {
				position: absolute;
				height: 600px;
				width: 100%;
				}
	}





/*SLIDER*/
@media screen and (max-width: 600px) {
.content {
	width: 50%;
}
}

.content > * {
color: #fff;
margin-bottom: 1rem;
}

.current .content {
opacity: 1;
transform: translateX(0);
transition: all 0.5s ease;
}

.arrow {
border: 2px solid white;
background-color: transparent;
color: #fff;
cursor: pointer;

height: 2rem;
width: 2rem;
border-radius: 50%;
position: absolute;
z-index: 1;
}
.arrow:hover {
background-color: #fff;
color: #777;
}

.next {
top: 50%;
right: 1.5rem;
}
.prev {
top: 50%;
left: 1.5rem;
}

hr {
height: 2px;
background: white;
width: 50%;
}

.logo-main {
position: absolute;
z-index: 999999;
width: 250px;
left: 40%;
top: 10rem;
}


.bloque {
	background-color: white;
	padding: 20px;
	text-align: center; /* Añadimos esta propiedad para centrar */
  }
  
  .bloque h1 {
	font-size: 32px;
	margin-bottom: 10px;
  }
  
  .bloque p {
	font-size: 16px;
	margin-bottom: 20px;
  }



/*CARTAS EVENTOS Y GALERIA FOTOS*/
  .card {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 10px;
	padding: 10px;
	width: calc(25% - 20px);
	height: calc(25% - 20px);
	position: relative;
	transition: transform 0.5s ease, opacity 0.5s ease;

  }
  .card img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-radius: 10px;
	transition: transform 0.3s ease;

  }
  
  .card:hover img {
	transform: scale(1.1);
	filter: brightness(0.7);
	cursor: pointer;
  }
  

  
  .card:hover .message-box {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
  }
  
  .message-box span {
	font-size: 24px;
	font-weight: bold;
  }
  
  .card h3 {
	font-size: 20px;
	border-radius:10px;
	background-color: #fcf82d;
	text-align: center;
	padding: 5px 10px;
	color: #000;
	margin-top: 10px;
	font-weight: bold;
}
  
  .card-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
  }
  
  .card-list::-webkit-scrollbar {
	width: 5px;
	height: 5px;
  }
  
  .card-list::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 5px;
  }
  
  .card-list::-webkit-scrollbar-track {
	background-color: #f2f2f2;
	border-radius: 5px;
  }
  @media (max-width: 800px) {
	.card-list {
		flex-direction: column;
	  }
	  
	  .card {
		width: 100%;
		height: auto;
		margin-right: 0;
	  }
	
	  .card img {
		width: 80%;
		height: 200px;
		margin-right: 10px;
		border-radius: 10px;
	  }
	}


/*CARD LIST EVENTS*/
.card-list-events{
	margin-top: 20px;
}

.card-events {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 10px;
	padding: 10px;
	width: calc(25% - 20px);
	height: calc(25% - 20px);
	position: relative;

  }
  
  .card-events img {
	width: 80%;
	height: 80%;
	object-fit: cover;
	border-radius: 10px;
	transition: transform 0.3s ease, filter 0.3s ease;
  }
  .past-events {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
  }

  @media screen and (max-width: 800px) {
	.card-events {
	  width: 100%;
	  height: auto;
	  padding: 10px;
	  margin: 5px 0;
	}
  
	.card-events img {
	  width: 60%;
	  height: 60%;
	  object-fit: cover;
	  border-radius: 5px;
	  transition: transform 0.3s ease, filter 0.3s ease;
	}
  }
  
  .card-events:hover img {
	transform: scale(1.1);
	filter: brightness(0.6);
	cursor: pointer;
  }
  
  .message-box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: none;
	background-color: rgba(255, 255, 0, 0.9);
	color: #000;
	padding: 10px;
	border-radius: 10px;
	z-index: 1;
  }
  
  .card-events:hover .message-box {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
  }
  

.button-events-container {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
  }
  
  .button-events-container button {
	background-color: #696969;
	color: white;
	border: none;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	width: 100px;
	margin-top: 20px;
  }
  
  .button-events-container button:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
  }
  
  .button-events-container button.active {
	background-color: yellow;
	color: black;
  }
  .entradas {
	text-align: center;
	font-weight: bold;
	padding: 20px;
	background-color: #fcf82d;}
	


/*IMAGE GALLERY Y SLIDER*/

body {
margin: 0 20px;
background-color: #2d2d2d;
}
.title-gallery {
text-align: center;
font-weight: bold;
padding: 20px;
background-color: #fcf82d;}



.image-gallery {
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
margin: 5px;
overflow: hidden;
}

.image-gallery__image {
width: 24%;
max-height: 300px;
margin: 5px;
object-fit: cover;
border-radius: 10px;
}

@media (max-width: 767px) {
.image-gallery {
	flex-direction: column;
}

.image-gallery__image {
	width: 100%;
	margin: 10px 0;
}
}

.slider-img-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.8);
z-index: 1;
display: flex;
justify-content: center;
align-items: center;
}

.slider-img {
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 90%;
max-height: 100vh;
overflow:auto;
}

.slider-img__image {
max-width: 100%;
max-height: 70vh;
object-fit: contain;
margin-bottom: 10px;
}

.slider-img__arrow {
position: absolute;
top: 50%;
transform: translateY(-50%);
width: 40px;
height: 40px;
background: rgba(255, 255, 255, 0.5);
border: none;
border-radius: 50%;
color: #222;
font-size: 20px;
text-align: center;
cursor: pointer;
}

.slider-img__arrow--left {
left: 10%;
}

.slider-img__arrow--right {
right: 10%;
}

.slider-img__thumbnails {
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
margin-top: 0px;
height: 35%;
}

.slider-img__thumbnail {
width: 10%;
height: 200px;
max-height: 80px;
margin: 5px;
object-fit: cover;
border-radius: 10px;
cursor: pointer;
}

.slider-img__thumbnail--selected {
border: 2px solid #fff;
}

.hide {
display: none;
}
.slider-img__close-button {
position: absolute;
top: 10px;
right: 10px;
z-index: 2;
margin: 0;
padding: 0;
width: 30px;
height: 30px;
font-size: 20px;
font-weight: bold;
color: #000000;
background-color: rgba(183, 183, 183, 0.5);
border-radius: 50%;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
}

.slider-img__close-button:hover {
background-color: rgba(255, 255, 255, 0.5);
color: #000;
	  }





.whatsapp-buttons {
margin-top: 20px;
display: flex;
flex-direction: column;

align-items: center;
}

.whatsapp-buttons__button {
display: flex;
align-items: center;
justify-content: center;
font-size: 1.2rem;
color: #fff;
background-color: #25d366;
border: none;
border-radius: 5px;
width: 500px; /* Set a fixed width */
height: 50px; /* Set a fixed height */
margin: 10px;
padding: 10px;
box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
transition: all 0.2s ease-in-out;
}

.whatsapp-buttons__button:hover {
background-color: #128c7e;
box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.whatsapp-buttons__button__icon {
margin-left: 10px;
font-size: 1.2rem;
}

.title__whatsapp {
background-color: #fcf82d;
color: "#fff";
font-size: 2rem;
text-align: center;
padding: 15px;
font-weight: bold;
}

@media only screen and (max-width: 800px) {

	.whatsapp-buttons__button {
		width: 400px; /* Set a fixed width */
		}
}


.croatiascript {
	position: absolute;
	height: 400%;
	width: 100%;
	background-color: #52c2f1;
	text-align: center;

}

.titles{
	background-color: #25d366;
	padding: 5px;
}

.croatiascript h1 {
font-weight: bold;
	font-size: 25px;
	padding-top: 5px;

}

.croatiascript h2 {
	font-family:Arial, Helvetica, sans-serif;
	font-size: 15px;
	padding-top: 5px;
}

.crocards{
	padding-top: 20px;
	padding-right: 10px;
}

@media only screen and (max-width: 800px) {
	.croatiascript {
		height: 400%;
		width: 100%;
		background-color: #52c2f1;
		text-align: center;
	
	}
}


.carousel-img {
    width: 100%;
    border-radius: 10px;
    height: auto;
    transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
	
}

.carousel-img:hover {
    filter: brightness(0.6);
}

.carousel-img-container.hovered .carousel-img {
    filter: brightness(0.6);
}

.carousel-img-container:hover .image-details {
    opacity: 1;
}

.carousel-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}

.carousel-img-container {
    width: calc(25% - 50px);
    flex-shrink: 0;
    margin: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.image-details {
    position: absolute;
    left: 0;
    right: 0;
	bottom: 0px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 1.2rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.image-details h3 {
    color: yellow;
    font-weight: bold;
}

.image-details p {
    text-align: justify;
}

.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    border: none;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s ease-in-out;
}

.carousel-arrow.prev {
    left: 10px;
    padding: 10px 15px;
    border-radius: 0 4px 4px 0;
}

.carousel-arrow.next {
    right: 10px;
    padding: 10px 15px;
    border-radius: 4px 0 0 4px;
}

.carousel-arrow.hide-arrow {
    display: none;
}

.carousel-img.animate-prev {
    transform: translateX(-25%);
}

.carousel-img.animate-next {
    transform: translateX(25%);
}

.carousel-img.animate-reset {
    transform: translateX(0);
}

.carousel-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

	
}

.carousel-title {
    font-size: 2rem;
    font-weight: bold;
    color: yellow;
    padding-top: 30px;
}

.carousel-subtitle {
    font-size: 1.5rem;
    color: #fff;
    font-family: 'Nunito', sans-serif;
}




  @media (max-width: 767px) {
	.carousel-wrapper {

		margin-top: 550px;
	  }


	  .carousel-img-container {
		width: calc(80% - 50px);
		flex-shrink: 0;
		margin: 0 10px;
		position: relative;
	  }

	  .carousel-title {

		  text-align: center;
	  }

	  .carousel-subtitle {

		text-align: center;
	  }
  }








  
  


  .footer {
	background-color: #949494;
	padding: 20px;
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
  }
  
  .footer__company {
	margin: 0;
	font-weight: bold;
	font-size: 16px;
	text-align: center;
	margin-bottom: 10px;
  }
  
  .footer__links {
	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
  }
  
  .footer__link-item {
	margin: 0 20px;
	font-size: 14px;
  }
  
  .footer__link {
	color: yellow;
	text-decoration: none;
  }
  
  .footer__link:hover {
	color: #ffffff;
  }





  /* Set font family and color for the entire component */
.PrivacyPolicy {
	font-family: Arial, sans-serif;

  }
  
  /* Style the heading elements */
  .PrivacyPolicy h1 {
	font-size: 2rem;
	color:yellow;
	font-weight: bold;
	margin-bottom: 1rem;
  }
  
  .PrivacyPolicy h2 {
	font-size: 1.5rem;
	color: yellow;
	font-weight: bold;
	margin-top: 2rem;
	margin-bottom: 1rem;
  }
  
  /* Style the paragraph elements */
  .PrivacyPolicy p {
	font-size: 1rem;
	color: white;
	line-height: 1.5;
	margin-bottom: 1rem;
  }
  
  /* Add some padding and a border to the entire component */
  .PrivacyPolicy {
	padding: 2rem;
	border-radius: 0.5rem;
  }





.center-container {
width: 33%;
border-radius: 0;
height: fit-content;
margin-left: 33%;
}
  @media (max-width: 767px) {

	.center-container {
		width: 20%;
		height: fit-content;
		margin-left: 20%;
  }}



.ticket-page {
	background-color: white;
}
.banner-tickets {
 width: 200%;

}

  

  
  